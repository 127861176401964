<template>
  <div class="survey-observation-details">
    <dashboard-layout>
      <template #header>
        <h4 class="align-self-center pb-0 mb-0">{{ $route.meta.title }}</h4>
      </template>
      <template #header-right>
        <switches
          v-if="vmbObservationData"
          v-model="showAll"
          theme="custom"
          color="primary vue-switcher-small text-center"
          class="mb-0 mr-4"
          label="All"
          @input="onSwitch"
        ></switches>
      </template>
      <template #body>
        <div v-if="vmbObservationData">
          <b-row>
            <b-col
              xl="3"
              lg="6"
              md="12"
              sm="12"
              class="mb-4"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <b-card no-body class="detail-card">
                <b-card-body>
                  <div class="action-toggle text-center">
                    <toggle-button
                      :value="getCurrentStatus(item.status)"
                      :labels="{ checked: 'Open', unchecked: 'Complete' }"
                      :color="'#2d7281'"
                      :width="80"
                      :sync="true"
                      @change="onCompleteSwitch($event, item)"
                    />
                  </div>
                  <b-container>
                    <b-row class="justify-content-center text-center">
                      <b-col class="col-12">
                        <b-avatar
                          :src="item.thumb"
                          size="3.5em"
                          :text="getNameInitials(item.details)"
                        ></b-avatar>
                      </b-col>
                      <b-col class="col-12 mt-3 p-0">
                        <h6 class="pb-0">{{ item.details }}</h6>
                        <b-badge class="ml-2" variant="outline-danger">
                          {{ item.day_name }}
                        </b-badge>
                        <b-badge class="ml-1" variant="outline-primary">
                          {{ item.week_name }}
                        </b-badge>
                        <vue-perfect-scrollbar
                          class="scroll dashboard-list-with-comments mt-3 p-0"
                          :settings="{
                            suppressScrollX: true,
                            wheelPropagation: false
                          }"
                        >
                          <h6>{{ item.title }}</h6>
                        </vue-perfect-scrollbar>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-else class="spinner">
          <ring-loader :color="loader.color"></ring-loader>
        </div>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { helperMixin } from '@/mixins/helperMixin'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import RingLoader from 'vue-spinner/src/RingLoader'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import Switches from 'vue-switches'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '@/components/Common/VuetablePaginationBootstrap'
export default {
  components: {
    DashboardLayout,
    RingLoader,
    ScaleLoader,
    Switches,
    vSelect,
    Vuetable,
    VuetablePaginationBootstrap
  },
  mixins: [dataPreloadMixin, helperMixin],
  data() {
    return {
      loader: {
        color: '#2d7281'
      },
      showAll: false,
      switches: {
        primary: false,
        secondary: false,
        primaryInverse: false,
        secondaryInverse: false
      },
      tableData: [],
      vmbData: []
    }
  },
  methods: {
    ...mapActions(['fetchVmbObservationData']),
    async filterVmbObservationByStatus(status = 1) {
      if (this.vmbObservationData[this.routeName] === undefined) {
        return
      }

      this.vmbData = await _.cloneDeep(this.vmbObservationData[this.routeName])

      // show incompleted records
      if (status === 1) {
        this.vmbData = this.vmbData.filter((item) => item.status === status)
      }

      // sort records
      this.tableData = await _.orderBy(
        this.vmbData,
        ['status', 'week_id', 'day_id', 'details'],
        ['asc', 'desc', 'desc', 'asc']
      )
    },
    getCurrentStatus(item) {
      return item === 2 ? true : false
    },
    async getVmbObservationData(status = 1) {
      if (this.routeName && this.currentSimulationTeam) {
        await this.fetchVmbObservationData({
          simulationId: this.currentSimulation.id,
          teamId: this.currentSimulationTeam.id,
          typeId: this.routeMetaId,
          status: status
        })
      }

      await this.filterVmbObservationByStatus(status)
    },
    async onCompleteSwitch(event, item) {
      let value = event.value ? 2 : 1
      item.status = value
      let obsUpdate = {
        id: item.id,
        type: this.routeMetaId,
        status: value
      }
      await this.updateVmbObservationStatus(obsUpdate)
    },
    async onSwitch(event) {
      if (event) {
        await this.getVmbObservationData(null)
      } else {
        await this.filterVmbObservationByStatus()
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentSimulation',
      'currentSimulationTeam',
      'currentUser',
      'status',
      'vmbObservationData',
      'vmbObservationTypes'
    ]),
    ...mapGetters('loading', ['loadingVmbData']),
    incompletedStatus() {
      return this.showAll ? null : 1
    },
    routeMetaId() {
      return this.$route.meta.id
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          this.getVmbObservationData()
        }
      },
      immediate: true
    },
    routeName: {
      async handler(newValue) {
        if (newValue) {
          this.getVmbObservationData()
        }
      },
      immediate: true
    },
    vmbObservationData: {
      async handler(newValue) {
        if (newValue) {
          this.filterVmbObservationByStatus(this.incompletedStatus)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
